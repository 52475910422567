import React, { createContext, useEffect, useState, useContext } from "react";
import {
  getDeviceDataAPI,
  exportCsvData,
  downloadCsvData,
} from "../../apis/Api";
import { GlobalStateContext } from "../../store/store";
import moment from "moment";
import downloadCsv from "../../constants/ContentFile.json";

export const DeviceDataContext = createContext({});
export const DeviceDataContextProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD")); // date
  const [deviceDataLabel, setDeviceDataLabel] = useState([]);
  const [deviceDataValue, setDeviceDataValue] = useState([]);
  const [defaultDataLabels, setDefaultDataLabels] = useState([
    {
      id: "VG299",
      label: "",
      checked: true,
      initialState: true,
      labelShowOnTable: true,
    },
    {
      id: "VG014",
      label: "",
      checked: true,
      initialState: true,
      labelShowOnTable: true,
    },
    {
      id: "VG093",
      label: "",
      checked: true,
      initialState: true,
      labelShowOnTable: true,
    },
    {
      id: "VG191",
      label: "",
      checked: true,
      initialState: true,
      labelShowOnTable: true,
    },
    {
      id: "VG251",
      label: "",
      checked: true,
      initialState: true,
      labelShowOnTable: true,
    },
    {
      id: "VG008",
      label: "",
      checked: true,
      initialState: true,
      labelShowOnTable: true,
    },
  ]);
  const [showLoader, setShowLoader] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [popupErrorMsg, setPopupErrorMsg] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState(
    new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
  );
  const [endTime, setEndTime] = useState(
    new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
  );
  const [exportPdfModal, setExportPdfModal] = useState(false);
  const [currentpageState, setCurrentPageState] = useState(null);
  const { state } = useContext(GlobalStateContext);
  const deviceModel = localStorage?.getItem("model");

  const productType = state?.productType;
  const serialNumber = localStorage?.getItem("serialNo_NavBar");
  const [isUrl, setIsUrl] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [successModal, setSuccessModal] = useState(false); // success modal show and hide
  const [sucessModalText, setSuccessModalText] = useState("");
  const [selectAllOnClick, setSelectAllOnClick] = useState(false);
  const [isDownloadComplete, setIsDownloadComplete] = useState(false);
  const [isExportCsvBtnEnable, setIsExportCsvBtnEnable] = useState(false);
  const [reportGeneratedStartDate,setReportGeneratedStartDate] = useState("")
  const [reportGeneratedEndDate,setReportGeneratedEndDate] = useState("")
  useEffect(() => {
    getDeviceData(deviceModel, productType, serialNumber, currentpageState);
     downloadCsv();
  }, []);

  // function to get data from API
  const getDeviceData = async (
    deviceModel,
    productType,
    serialNumber,
    pageState
  ) => {
    setShowLoader(true);
    const data = {
      model: deviceModel, //deviceModel,
      type: productType,
      date: date,
      srno: serialNumber,
      fetchSize: 10,
      pageState: pageState,
    };
    try {
      const response = await getDeviceDataAPI(data);
      setShowLoader(false);
      if (response?.data?.status === 200) {
        const deviceDataList = response?.data?.data?.label;
        // get default ids of data values in table
        const defaultDataLabelsIdsArray = defaultDataLabels.map((item) => {
          return item.id;
        });

        //ordering response payload with default ids(default datalabel id)
        const orderedResponsePayload = [];
        response?.data?.data?.rows?.forEach((item) => {
          // payload of default datalabels
          const defaultPayload = item?.payload?.filter((item) =>
            defaultDataLabelsIdsArray.includes(item?.kpi)
          );
          const nonDefaultPayload = item?.payload?.filter(
            (item) => !defaultDataLabelsIdsArray.includes(item?.kpi)
          );
          const orderedPayload = [...defaultPayload, ...nonDefaultPayload];
          orderedResponsePayload.push({
            time: item.time,
            payload: orderedPayload,
          });
        });

        setDeviceDataValue(orderedResponsePayload); //orderd data label with default labels on the top
        setCurrentPageState(response?.data?.data?.pageState);
        // iterate array and change key  "kpi to id" and "name to label"
        const keyUpdatedArray = deviceDataList?.map((item) => {
          return {
            id: item?.kpi,
            label: item?.name,
          };
        });

        const defaultDataLabelsId = defaultDataLabels.map((item) => item.id);
        const updatedDataLabels = keyUpdatedArray
          ?.filter((item) => defaultDataLabelsId.includes(item.id))
          ?.map((item) => {
            return {
              ...item,
              label: item?.label,
              checked: true,
              initialState: true,
              labelShowOnTable: true,
            };
          });
        setDefaultDataLabels(updatedDataLabels);
        if (deviceDataLabel.length == 0) {
          const updatedDeviceDataLabels = keyUpdatedArray
            ?.filter((item) => !defaultDataLabelsId.includes(item.id))
            ?.map((item) => {
              return {
                ...item,
                label: item?.label,
                checked: false,
                initialState: false,
                labelShowOnTable: false,
              };
            });
          setDeviceDataLabel(updatedDeviceDataLabels);
        }
      }
    } catch (error) {
      setShowLoader(false);
      setShowErrorPopup(true);
      setPopupErrorMsg("Something went wrong");
    }
  };

  // function to cancel modal
  const toggleShow = () => {
    setShowModal(!showModal);

    // When the cancel button is clicked, close the settings modal by unchecking any previously checked items.
    const updatedCheckBoxItems = deviceDataLabel?.map((item) => {
      return {
        ...item,
        checked: item?.initialState,
      };
    });
    setDeviceDataLabel([...updatedCheckBoxItems]);
  };

  // on click check box storing checked items to a state
  const onChangeCheckbox = (id, label, value, checked) => {
    const currentState = deviceDataLabel;
    const itemIndex = currentState?.findIndex((item) => item?.id === id);
    currentState[itemIndex] = {
      ...currentState[itemIndex],
      id,
      label,
      checked: !checked,
      labelShowOnTable: checked,
    };
    setDeviceDataLabel([...currentState]);
  };
  // function to add checked items to datatable
  const addColumn = () => {
    const updatedCheckBoxItems = deviceDataLabel?.map((item) => {
      return {
        ...item,
        initialState: item?.checked,
        labelShowOnTable: item?.checked,
      };
    });

    setDeviceDataLabel([...updatedCheckBoxItems]);
    setShowModal(false);
  };
  // export data as csv function
  const exportDataCsv = async () => {
    setExportPdfModal(false);

    setShowLoader(true);
    const data = {
      key: "Data",
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      srno: serialNumber,
      model: deviceModel,
      type: productType,
      startTime: startTime,
      endTime: endTime,
    };

    try {
      const response = await exportCsvData(data);
   
      setIsDataLoaded(true);
      setIsReportGenerated(false);
      setShowLoader(false);
      setSuccessModal(true);
      setSuccessModalText(
        "Data is exported successfully,Report generation in progress"
      );
    } catch (error) {
      setShowLoader(false);
      setShowErrorPopup(true);
      setPopupErrorMsg("Something went wrong ");
    }
  };
  // refresh click on for csv
  const refreshClick = async () => {
    setIsUrl("");
    setShowLoader(true);
    if (isReportGenerated) {
      setIsDataLoaded(false);
    }
    downloadCsv();
  };

  // download csv
  const downloadCsv = async () => {
  
    const downLoadDataKey = {
      key: "Data",
      srno: serialNumber,
    };
    setShowLoader(true);
    try {
      const response = await downloadCsvData(downLoadDataKey);
      setIsUrl(response.data.data.url);
      setIsReportGenerated(response.data.data.reportGenerated);
      setReportGeneratedStartDate(response.data.data.startDate)
      setReportGeneratedEndDate(response.data.data.endDate)
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      setShowErrorPopup(true);
      setPopupErrorMsg("Something went wrong");
    }
  };
  // check downloAD
  const ondownloadClick = () => {
    if (isUrl) {
      setShowLoader(true);
      window.open(isUrl, "_self");
    }
    setShowLoader(false);
  };
  // function to change date on export graph
  const selectExportDate = (fromDate, toDate) => {
    setStartDate(fromDate);
    setEndDate(toDate);
  };
  // function to set  time range  for export graph
  const exportTimeRange = (startTime, endTime) => {
    setStartTime(startTime);
    setEndTime(endTime);
  };
  // pagination fuction
  const onHandlePagination = (pageState) => {
    getDeviceData(deviceModel, productType, serialNumber, pageState);
  };
  const selectAllCheckboxOnChange = (id, label, value, checked) => {
    setSelectAllOnClick(!selectAllOnClick);
    const checkBoxStatus = deviceDataLabel.every((item) => {
      return item.checked;
    });
    const updatedCheckBoxItems = deviceDataLabel?.map((item) => {
      return {
        ...item,
        checked: !checkBoxStatus,
        // isSubmitted: false,
      };
    });
    setDeviceDataLabel([...updatedCheckBoxItems]);
  };

  const providerValue = {
    addColumn,
    onChangeCheckbox,
    toggleShow,
    setShowModal,
    showModal,
    deviceDataLabel, // device data label storing state
    deviceDataValue,
    showLoader, // state to show and hide loader
    showErrorPopup, // state for error popup
    setShowErrorPopup, // set state for error popup
    popupErrorMsg, // state to store error message from API
    exportDataCsv,
    selectExportDate,
    exportPdfModal,
    setExportPdfModal,
    onHandlePagination, // pagination click
    currentpageState,
    isUrl,
    refreshClick, //state to show refresh button
    downloadCsv, // function to download url
    isDataLoaded, // state to show refresh button
    setIsDataLoaded,
    showDownloadButton,
    ondownloadClick,
    setIsReportGenerated,
    isReportGenerated, // state to check url is generated or not
    successModal,
    setSuccessModal,
    sucessModalText,
    setSuccessModalText,
    selectAllCheckboxOnChange,
    selectAllOnClick,
    defaultDataLabels,
    exportTimeRange,
    isDownloadComplete,
    setIsDownloadComplete,
    isExportCsvBtnEnable,
    setIsExportCsvBtnEnable,
    reportGeneratedStartDate,setReportGeneratedStartDate,
    reportGeneratedEndDate,setReportGeneratedEndDate
  };
  return (
    <DeviceDataContext.Provider value={providerValue}>
      {children}
    </DeviceDataContext.Provider>
  );
};
