import React, { useContext, useEffect, useState } from "react";
import { StabilizerDeviceDataContext } from "../StabilizerDeviceDataView";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBRow,
} from "mdb-react-ui-kit";
import {
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import exportGraph from "../../../../assets/images/exportGraph.png";
import settingIcon from "../../../../assets/images/icons/Setting.svg";
import "./stabilizerDeviceData.css";
import ThemeCard from "../../../../components/ThemeCardComponent/ThemeCard";
import CheckBoxComponent from "../../../../components/CheckBoxComponent/CheckBoxComponent";
import Loader from "../../../../components/LoaderComponent/LoaderComponent";
import Alertpopup from "../../../../components/AlertPopup/AlertPopup";
import ErrorIcon from "../../../../assets/images/icons/Error.svg";
import moment from "moment";
import DatePickerModal from "../../../../components/DatePickerModal/DatePickerModal";
import { Pagination } from "../../../../components/Pagination/Pagination";
import NoDataImage from "../../../../assets/images/NoData-folder-image.svg";
import RefreshIcon from "../../../../assets/images/icons/Refresh.svg";
import downloadcsvIcon from "../../../../assets/images/icons/downloadcsv.svg";
import SuccessImage from "../../../../assets/images/Success.svg";
const StabilizerDeviceData = () => {
  const {
    addColumn,
    onChangeCheckbox,
    toggleShow,
    setShowModal,
    showModal,
    deviceDataLabel, // device data label storing state
    deviceDataValue,
    showLoader, // state to show and hide loader
    showErrorPopup, // state to show and hide error popup
    setShowErrorPopup, // set state for error popup
    popupErrorMsg, // state to store error message from API
    exportDataCsv,
    selectExportDate,
    exportPdfModal,
    setExportPdfModal,
    onHandlePagination, // pagination click
    currentpageState,
    refreshClick,
    isDataLoaded, // state to show refresh button
    ondownloadClick, // click to download url
    isReportGenerated, // state to check url is generated or not
    successModal,
    setSuccessModal,
    sucessModalText,
    setSuccessModalText,
    selectAllCheckboxOnChange,
    selectAllOnClick,
    defaultDataLabels,
    exportTimeRange,
    reportGeneratedStartDate,  // start  dated show export csv
    reportGeneratedEndDate, // end  dated show export csv
  } = useContext(StabilizerDeviceDataContext);
  const date = new Date();
  const reportStartDate = moment(reportGeneratedStartDate, "YYYY-MM-DD").format(
    "MMM-D,YYYY"
  );
  const reportEndDate = moment(reportGeneratedEndDate, "YYYY-MM-DD").format(
    "MMM-D,YYYY"
  );
  const deviceDataLabelsCombined = [...defaultDataLabels, ...deviceDataLabel];
  return (
    <div className="water-heater-data-main">
      {showLoader ? <Loader /> : ""}
      {/* heading */}
      <div className="device-heading-row">
        <div className="device-data-tilte"> Data</div>

        <div className="icon-button-row">
          {isReportGenerated && (
            <div className="water-heater-selcted-date-range">
              Report From
              <div className="selcteddate-range">
                {reportStartDate} - {reportEndDate}
              </div>
            </div>
          )}

          <div>
            {/* Render the Refresh button only if data is loaded */}
            {isDataLoaded && isReportGenerated === false ? (
              <div className="refresh-button-label">
                <div className="arrow_box">
                  <p>Report generation in progress. Click refresh button</p>
                </div>
                <ButtonComponent
                  coloredButton={true}
                  customClass="settings-button"
                  buttonImage={RefreshIcon}
                  onClick={refreshClick}
                />
              </div>
            ) : (
              ""
            )}

            {isReportGenerated && (
              <ButtonComponent
                text={"Download CSV"}
                buttonImage={downloadcsvIcon}
                buttonWidth="170px"
                onClick={ondownloadClick}
              />
            )}
          </div>

          <div>
          
              <ButtonComponent
                coloredButton={true}
                customClass="settings-button"
                buttonImage={settingIcon}
                onClick={toggleShow}
              />
         
          </div>

          <div>
            
              <ButtonComponent
                coloredButton={true}
                text={"Export as CSV"}
                customClass={"export-graph-button"}
                onClick={() => setExportPdfModal(true)}
              />
        
          </div>
        </div>
      </div>
      {/* table card */}
      <MDBRow>
        <ThemeCard>
          <div className="table-responsive">
            <MDBTable>
              <MDBTableHead>
                <tr className="main-heading">
                  <th>Created on</th>
                  <th
                    colspan={
                      deviceDataLabelsCombined?.filter(
                        (item) => item?.checked === true
                      ).length
                    }
                  >
                    <span>Data</span>
                  </th>
                </tr>
                <tr className="sub-heading">
                  <th></th>
                  {deviceDataLabelsCombined
                    ?.filter((item) => item?.labelShowOnTable === true)
                    ?.map((item, index) => {
                      return (
                        <th scope="col" key={index}>
                          {item?.label}
                        </th>
                      );
                    })}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {deviceDataValue?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>
                          <span className="data-date">
                            {moment(item?.time).format("ll")}
                          </span>
                          <br />
                          <span className="data-time">
                            {moment(item?.time).format("HH:mm:ss")}
                          </span>
                        </td>
                        {item?.payload?.map((data, index) => {
                          if (
                            deviceDataLabelsCombined
                              ?.filter((item) => item?.labelShowOnTable)
                              ?.map((item) => item?.id)
                              ?.includes(data?.kpi)
                          ) {
                            return (
                              <td key={index}>
                                {data?.value ? data?.value : "-"}
                              </td>
                            );
                          }
                        })}
                      </tr>
                      <hr />
                    </>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
            {deviceDataValue.length === 0 ? (
              <div className="no-data-container">
                <img src={NoDataImage} alt="No data" />
                <span className="no-data-title">No Data Found For Today</span>
                <span className="no-data-text">No result to be shown for today you can export it from export button given above</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </ThemeCard>
        {deviceDataValue ? (
          <Pagination
            customClass="pagination-mt"
            onChnagePagination={(pageState) => onHandlePagination(pageState)}
            currentPageState={currentpageState}
            dataTablevalue={deviceDataValue}
          />
        ) : (
          ""
        )}
      </MDBRow>
      {/* modal */}
      <MDBModal show={showModal} setShow={setShowModal} tabIndex="">
        <MDBModalDialog size="xl" className="parameter-modal">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="modal-title">
                <div className="title-container">
                  <div>Parameters</div>
                  <div className="select-all-checkbox-items">
                    <CheckBoxComponent
                      id={1}
                      label={"Select All"}
                      onChangeCheckbox={selectAllCheckboxOnChange}
                      customClass={"select-all-checkbox"}
                      checked={deviceDataLabel.every((item) => {
                        return item.checked;
                      })}
                    />
                  </div>
                </div>
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody className="row">
              {deviceDataLabel
                ?.filter(
                  (item) =>
                    !["VG003", "VG012", "VG014", "VG015","VG132","VG008"].includes(item.id)
                )
                .map((item, index) => {
                  return (
                    <div className="col-md-3">
                      <div className="checkbox-items">
                        <CheckBoxComponent
                          key={index}
                          id={item.id}
                          label={item.label}
                          onChangeCheckbox={onChangeCheckbox}
                          checked={item.checked}
                        />
                      </div>
                    </div>
                  );
                })}
            </MDBModalBody>

            <MDBModalFooter>
              <div className="checkbox-modal-footer">
                <ButtonComponent
                  text={"Cancel"}
                  coloredButton={false}
                  onClick={toggleShow}
                />
                <ButtonComponent
                  text={"Submit"}
                  coloredButton={true}
                  onClick={addColumn}
                />
              </div>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* Alert pop to show error */}
      <Alertpopup
        image={ErrorIcon}
        setShowModal={setShowErrorPopup}
        showModal={showErrorPopup}
        titleText="Error"
        DetailText={popupErrorMsg}
        modalType="error-modal"
        alertButtonText="Continue"
      />
      {/* Alert pop to show succes */}
      <Alertpopup
        showModal={successModal}
        setShowModal={setSuccessModal}
        modalType={true}
        titleText={"Success"}
        DetailText={sucessModalText}
        image={SuccessImage}
        alertButtonText={"Continue"}
      ></Alertpopup>
      <DatePickerModal
        title={"Export as CSV"}
        showModal={exportPdfModal}
        setShowModal={setExportPdfModal}
        initialDate={date}
        // dateRange={30}
        getSelectedDates={(fromDate, toDate) => {
          selectExportDate(fromDate, toDate);
        }}
        getSelectedTime={(startTime, endTime) => {
          exportTimeRange(startTime, endTime);
        }}
        onClickSubmit={exportDataCsv}
        SubmitButtonText={"Export as CSV"}
      />
    </div>
  );
};

export default StabilizerDeviceData;
